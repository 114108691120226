// ------------------------------------------------------------------------------
// ---------------------------------------------------------------------- Imports
// ------------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import classnames from 'classnames'

import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { compiler } from 'markdown-to-jsx'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Slider from '../slider'
import '../slider/style.less'

import Gallery from '../gallery'
import '../gallery/style.less'

import Audio from '../audio'
import '../audio/style.less'

import Video from '../video'
import '../video/style.less'

import ArrowLeft from '../svg/arrow-left'
import ArrowRight from '../svg/arrow-right'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** BlockParser */
const BlockParser = ({ block, locale }) => {
  let {
    type,
    subType = undefined,
    lang,
    content,
    resources,
    resource,
    caption,
  } = block

  if (isNull(lang) || isUndefined(lang)) {
    lang = 'en'
  }

  let returnThis = <Fragment />

  if (lang === locale) {
    if (type === 'space') {
      returnThis = <br className={classnames(subType)} />
    }

    if (type === 'carousel') {
      returnThis = (
        <Slider className={classnames(subType)} resources={resources} />
      )
    }

    if (type === 'text') {
      returnThis = (
        <p className={classnames(subType)}>
          {compiler(content, { wrapper: null })}
        </p>
      )
    }

    if (type === 'title') {
      returnThis = <h3 className={classnames(subType)}>{content}</h3>
    }

    if (type === 'image') {
      const image = getImage(resource)

      if (isNull(caption) === false) {
        returnThis = (
          <div className={classnames('with-caption flow', subType)}>
            <GatsbyImage image={image} />
            <p className="caption">{caption}</p>
          </div>
        )
      } else {
        returnThis = (
          <GatsbyImage className={classnames('flow', subType)} image={image} />
        )
      }
    }

    if (type === 'gallery') {
      const images = []
      map(resources, ({ cover }) => {
        images.push({
          data: cover,
          height: cover.childImageSharp.gatsbyImageData.height,
          width: cover.childImageSharp.gatsbyImageData.width,
        })
      })

      returnThis = <Gallery className={classnames(subType)} images={images} />
    }

    if (type === 'video') {
      returnThis = <Video className={classnames(subType)} url={content} />
    }

    if (type === 'audio') {
      returnThis = <Audio className={classnames(subType)} url={content} />
    }
  }

  return returnThis
}

/** Wrapper */
const Wrapper = ({ block, locale }) => {
  const {
    type,
    subType = undefined,
    contentBlocks: childContentBlocks = {},
  } = block

  let returnThis = <Fragment />

  if (type === 'block') {
    returnThis = (
      <div className={classnames('block', 'flow', subType)}>
        <BlockParser block={childContentBlocks} locale={locale} />
      </div>
    )
  } else if (type === 'look') {
    returnThis = (
      <div className={classnames('look', 'flow', subType)}>
        <Carousel
          dots
          arrows
          prevArrow={<ArrowLeft />}
          nextArrow={<ArrowRight />}
        >
          {map(childContentBlocks, (childContentBlock) => (
            <BlockParser block={childContentBlock} locale={locale} debug />
          ))}
        </Carousel>
      </div>
    )
  } else {
    returnThis = <BlockParser block={block} locale={locale} />
  }

  return returnThis
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Wrapper
